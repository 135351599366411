import $ from 'jquery';

window.jQuery = $;
window.$ = $;

var error;
var dynamicErrorMsg = function () {
    return error;
};

$.validator.addMethod("phone",
    function (phone_number, element, params) {
        var data = 'number=' + encodeURIComponent(phone_number);
        var response = false;
        params['error'] = 'Numero di telefono non valido';
        $.ajax({
            url: 'https://tools.alchemylab.net/api/phone',
            data: data,
            dataType: "json",
            async: false,
            success: function (json) {
                error = (json.value) ? '' : 'Numero non valido';
                response = json.value;
            },
            error: function (xhr, status, errorThrown) {
                console.log("Error: " + errorThrown);
                console.log("Status: " + status);
                console.dir(xhr);
                response = false;
                error = errorThrown;
            }
        });
        return response;
    },
    dynamicErrorMsg
);

$('#form').validate({
    rules: {
        // simple rule, converted to {required:true}
        'number': {
            required: true,
            phone: true
        }
    },
    messages: {
        'number': {
            required: "Campo obbligatorio"
        }
    },
    submitHandler: function (form, event) {
        event.preventDefault();
    }
});
