/**
 *  JS: resources/views/assets/js/cookies.js
 *
 */

import $ from 'jquery';
import Cookies from 'js-cookie';

console.log($);

$(document).ready(function () {
    // Check if the 'cookie_bar' cookie is set
    if (!Cookies.get('cookie_bar')) {
        $('.cookie_bar').show();
    }
    else {
        $('.cookie_bar').hide();
    }

    // When the close button is clicked
    $('body').on('click', '.cookies--close', function () {
        $('.cookie_bar').hide();
        // Set the 'cookie_bar' cookie to expire in 30 days
        Cookies.set('cookie_bar', '1', {expires: 30, path: '/'});
    });
});
