// app.js

import 'bootstrap/dist/js/bootstrap.bundle.min';
// Import jQuery and assign it to the global window object
import $ from 'jquery';
// Import jQuery Validation
import 'jquery-validation';

// Import jQuery Cookie
import 'jquery.cookie';
import 'bigslide/dist/bigSlide.min';

// Importing files from the root js directory
import './cookies.js';
import './phone-validate.js';
import images from './importImages';


window.jQuery = $;
window.$ = $;
